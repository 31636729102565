import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const SecondPage = () => (
  <Layout>
    <h1>Shooters Diary 2</h1>
    <StaticImage
      src="../images/home.png"
      loading="eager"
      width={180}
      quality={50}
      formats={["auto", "webp", "avif"]}
      alt="home"
      style={{ marginBottom: `var(--space-3)` }}
    />
    <StaticImage
      src="../images/create.png"
      loading="eager"
      width={180}
      quality={50}
      formats={["auto", "webp", "avif"]}
      alt="create"
      style={{ marginBottom: `var(--space-3)` }}
    />
    <p>
      <Link to="/">Go back to the homepage</Link>
    </p>
  </Layout>
)

export const Head = () => <Seo title="Page two" />

export default SecondPage
